import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Text, Link } from 'components/atoms';

const FooterFullItem = ({ data, isInverted }) => {
  const { title, list, isOpacity, bold } = data;
  const items = list.map((item, index) => {
    const { title, url } = item;

    // eslint-disable-next-line no-useless-escape
    const link = /\/\/[a-z0-9\.]+/.test(url)
      ? // eslint-disable-next-line no-useless-escape
        (url || '').split(/\/\/[a-z0-9\.]+/)[1]
      : url;

    return (
      <React.Fragment key={index}>
        {url ? (
          <Link href={link} className={cn({ 'footer-full__item__link': bold })}>
            <Text
              className={cn(
                bold ? 'footer-full__item__title' : 'footer-full__item__text'
              )}
              color={cn({
                'dark-purple': !isInverted,
                'transparent-green': isInverted,
              })}
            >
              {title}
            </Text>
          </Link>
        ) : (
          <Text
            className={cn('footer-full__item__text', {
              'footer-full__item__title_gray': isOpacity,
            })}
            color={cn({
              'dark-purple': !isInverted,
              'transparent-green': isInverted,
            })}
          >
            {title}
          </Text>
        )}
      </React.Fragment>
    );
  });

  const titleData = title ? (
    <Text
      className={cn(
        'footer-full__item__title',
        'footer-full__item__title_bold',
        {
          'footer-full__item__title_gray': isOpacity,
        }
      )}
      color={cn({
        'dark-purple': !isInverted,
        'transparent-green': isInverted,
      })}
    >
      {title}
    </Text>
  ) : null;

  return (
    <div className="footer-full__item">
      {titleData}
      <div
        className={cn('footer-full__item__list', {
          'footer-full__item__list_gray': isOpacity,
        })}
      >
        {items}
      </div>
    </div>
  );
};

FooterFullItem.defaultProps = {
  className: '',
};

FooterFullItem.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  isOpacity: PropTypes.bool,
};

export default FooterFullItem;
