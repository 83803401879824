import React from 'react';
import cn from 'classnames';

import './index.scss';

import { Container, Row, Column, Text, Link, Icon } from 'components/atoms';
import FooterFullItem from 'components/molecules/FooterFullItem';

import {
  useFooterItems,
  useFooterLocations,
  useOptions,
  useOffices,
} from 'graphql/hooks';

const currentYear = () => {
  let date = new Date();
  const year = date.getFullYear();

  return year;
};

const FooterFull = ({ footerFullInverted, data }) => {
  let isInverted = footerFullInverted;

  const allLocation = useFooterLocations();
  const allItems = useFooterItems();
  const email = useOptions().options.contact_email;
  const allOffices = useOffices();

  const footerColumnsData = allLocation.filter(item =>
    /footer_column_*/.test(item.node.wordpress_id)
  );
  const footerSocialData = allLocation.find(item =>
    /footer_menu_2/.test(item.node.wordpress_id)
  );
  const footerPagesData = allLocation.find(item =>
    /footer_menu_1/.test(item.node.wordpress_id)
  );

  let footerColumns = footerColumnsData.map(({ node }) => {
    const itemObject = allItems.find(
      ({ node: itemNode }) => itemNode.wordpress_id === node.menu_id
    );

    const { node: item } = itemObject;
    const { name, items } = item;
    if (name === 'Main') {
      return { list: items, bold: true };
    } else {
      return { title: name, list: items };
    }
  });

  const offices = allOffices.map(({ node }) => {
    const { acf } = node;
    const { address, country } = acf;
    return { title: country, list: [{ title: address }], isOpacity: true };
  });

  footerColumns = footerColumns.concat(offices);

  const socials = allItems.find(
    ({ node: itemNode }) =>
      itemNode.wordpress_id === footerSocialData.node.menu_id
  );

  const { items: socialItems } = socials.node;

  const footerSocials = socialItems.map((item, index) => {
    const { title, url } = item;

    return (
      <Link
        href={url}
        isRouter={false}
        target="_blank"
        rel="nofollow"
        key={index}
      >
        {isInverted ? (
          <Icon
            variant={cn(
              { 'behance-text-light': title === 'Behance' },
              { 'dribbble-text-light': title === 'Dribbble' },
              { 'instagram-text-light': title === 'Instagram' }
            )}
          />
        ) : (
          <Icon
            variant={cn(
              { 'behance-text': title === 'Behance' },
              { 'dribbble-text': title === 'Dribbble' },
              { 'instagram-text': title === 'Instagram' }
            )}
          />
        )}
      </Link>
    );
  });

  const privacyItem = allItems.find(
    ({ node: itemNode }) =>
      itemNode.wordpress_id === footerPagesData.node.menu_id
  ).node;

  const privacyItemLinks = privacyItem.items.map((item, i) => {
    return (
      <Link href={item.url} isRouter={false} target="_blank" key={i}>
        <Text
          className="footer-full__privacy-text"
          color={cn({
            'dark-purple': !isInverted,
            'transparent-green': isInverted,
          })}
        >
          {item.title}
        </Text>
      </Link>
    );
  });

  const copyrightText = 'Ester Digital is an independent company. Mentioned brands and companies are trademarked brands. © 2015–%d www.ester.co. All rights reserved.'.replace(
    '%d',
    currentYear()
  );

  const lists = footerColumns.map((item, index) => (
    <FooterFullItem
      data={item}
      key={index}
      isInverted={isInverted}
    ></FooterFullItem>
  ));

  return (
    <footer className={cn('footer-full', { 'footer-full_dark': isInverted })}>
      <Container>
        <Row className="footer-full__main">
          <Column>
            <div className="footer-full__main__block">{lists}</div>
          </Column>
        </Row>
        <Row>
          <Column className="footer-full__email col-xlsmall-3">
            <Link href={`mailto:${email}`} isRouter={false}>
              <Text
                tag="p"
                className={cn('footer-full__email-text headline-3')}
                color={cn({
                  'dark-purple': !isInverted,
                  'transparent-green': isInverted,
                })}
              >
                {email}
              </Text>
            </Link>
          </Column>
        </Row>
        <Row className="footer-full__socials">
          <Column className="col-xlsmall-3">
            <div className="footer-full__socials-block">{footerSocials}</div>
          </Column>
          <Column className="offset-xlsmall-5 col-xlsmall-4">
            <div className="footer-full__privacy ">{privacyItemLinks}</div>
          </Column>
        </Row>
      </Container>
      <Container type="fluid">
        <Row
          className={cn('footer-full__copyright', {
            'footer-full__copyright_dark': isInverted,
          })}
        >
          <Column>
            <Text
              tag="span"
              color={cn({
                'dark-purple': !isInverted,
                'transparent-green': isInverted,
              })}
              className="footer-full__idiomatic-text"
            >
              {copyrightText}
            </Text>
          </Column>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterFull;
